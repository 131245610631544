.full-height {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 700px; */
  width: 100%;
  background-color: #001d3d;
  margin: 0 auto;
}
.login-page {
  background-color: white;

  border-radius: 0.5rem;
  padding: 1.5rem;
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
}

.login-page-header-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;
}

.login-page-header-container img {
  width: 30px;
  cursor: pointer;
}

.login-page-head h2 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.login-page-head button {
  color: #212121;
  font-weight: bold;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}

.login-page-divider {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.login-page-divider .div1,
.div2 {
  width: 40%;
  height: 1.3px;
  background-color: #d3d3d3;
}

.login-page-btn {
  width: 100%;
  margin-top: 1rem;
}

.login-page-auth-btn {
  padding: 10px;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0.8rem 0;
  width: 100%;
  outline: 2px solid rgb(211, 211, 211);
  border-radius: 5px;
  cursor: pointer;
}

.login-page-auth-btn img {
  width: 30px;
}

.logout-tab {
  /* margin: 0.5rem; */
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.9); */
  padding: 1.5rem;
  border-radius: 0.5rem;
  /* max-width: 500px; */
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  gap: 1rem;
}

.logout-tab h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.logout-tab p {
  font-size: 0.8rem;
  margin-bottom: 1.5rem;
}

.user-profile-sidebar {
  min-height: 100vh;
}
