/* Add to your CSS */
.html-editor-toolbar {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f8f9fa;
  border: 1px solid var(--border-color);
  border-bottom: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  gap: 4px;
}

.toolbar-group {
  display: flex;
  gap: 2px;
}

.toolbar-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  color: var(--text-color);
  transition: all 0.2s;
}

.toolbar-button:hover {
  background-color: var(--secondary-color);
  border-color: var(--border-color);
}

.toolbar-separator {
  width: 1px;
  height: 24px;
  background-color: var(--border-color);
  margin: 0 8px;
}

.template-select {
  padding: 6px 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

/* Update textarea styles */
.textarea {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
