.auction-list {
  width: 100%;
  /* padding: 0.5rem; */
  border-radius: 4px;
  background-color: white;
}

.report-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  transition: all 200ms ease;
  border-radius: 0.5rem;
}

.report-item {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.report-item:hover {
  background-color: #c7f9cc;
}
.report-item img {
  /* height: 100%; */
  /* width: 100%; */
}

.report-item:last-child {
  border-bottom: none;
}

.report-item h3,
.report-item p,
.report-item .span,
.report-item .tag,
.a-btns {
  /* flex-grow: 1; */
  border-right: 1px solid #ddd;
  padding: 8px;
}

.report-item .tag {
  display: flex;
  gap: 2px;
  border-right: 0;
}

.report-item h3 {
  /* flex-grow: 2; */
  grid-column: span 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.5rem;
}

.report-item p {
  grid-column: span 2;
}

.a-btns {
  display: flex;
  gap: 5px;
  border-right: none;
  align-items: center;
  justify-content: center;
}

.report-item p {
  flex-grow: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.bgImg-reports {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
}

.reports-con {
  padding: 0.7rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
}

.rep-flex-con {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.7rem;
}

.rp {
  margin-bottom: 0.7rem;
}

.main-reps {
  margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .report-item {
    grid-template-columns: repeat(6, 1fr);
  }
  .report-item p {
    display: unset;
  }
}

@media (min-width: 900px) {
  .report-item {
    grid-template-columns: repeat(8, 1fr);
  }
  /* .reports-con {
    grid-template-columns: repeat(2, 1fr);
  } */
}

@media (min-width: 1100px) {
  .report-item {
    grid-template-columns: repeat(8, 1fr);
  }
  .reports-con {
    grid-template-columns: repeat(2, 1fr);
  }
}
