.nav {
  position: absolute;
  width: 60px;
  height: 100%;
  z-index: 23;
  /* background: var(--bg-secondary); */
  background-color: #fff;
  transition: 0.5s;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.nav::-webkit-scrollbar {
  display: none;
}

.nav.active {
  width: 300px;
}

/* nav:hover {
  width: 300px;
} */

.nav ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.nav ul li {
  list-style: none;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.nav ul li div:hover {
  /* color: var(--bg-primary); */
  color: white;
}

.nav ul li:hover div::before {
  background-color: #4c956c;
  width: 100%;
}

.nav ul li div {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* color: var(--cl-text); */
  transition: 0.2s;
}

.nav ul li div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: 0.2s;
}

.nav ul li div .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.nav ul li div .title {
  position: relative;
  font-size: 0.85em;
}

.nav ul li div .icon * {
  font-size: 1.1em;
}

.nav ul li div.toggle {
  border-bottom: 3px solid var(--bg-primary);
}

.toggle {
  cursor: pointer;
}

.adminHeader {
  display: none;
}

@media (max-width: 768px) {
  .adminHeader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    height: 60px;
    background: var(--bg-secondary);
    color: var(--cl-text);
  }

  .adminHeader div {
    color: var(--cl-text);
  }

  .nav {
    left: -60px;
    background-color: #e9ecef;
  }

  .nav.active {
    left: 0;
    width: 100%;
  }

  .nav ul li div.toggle {
    display: none;
  }
}

:root {
  --bg-primary: #41444b;
  --bg-secondary: #52575d;
  --bg-active: #fddb3a;
  --cl-text: #f6f4e6;
}

.tab-content {
  /* background-color: #670ed8; */
  /* margin-left: 0rem; */
}

.contain {
  margin-left: 0rem;
}

@media (min-width: 768px) {
  .nav {
    position: fixed;
  }
  .contain {
    margin-left: 4rem;
  }
}

.smallDot {
  width: 10px !important;
  height: 10px;
  border-radius: 999px;
  background-color: #ef233c;
  border: 2px solid #1b263b;
  position: absolute !important;
  top: 20px;
  left: 8px;
}

.nav ul li:hover .smallDot {
  opacity: 0;
}
