.auction-from {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.auction-form-input {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.auction-form-input input,
.auction-form-input textarea {
  width: 100%;
  padding: 0.9rem;
  margin-top: 0.6rem;
  border: 0;
  outline: 1.2px solid #ced4da;
  border-radius: 4px;
}

.auction-form-input textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  max-height: 350px;
}

.auction-form-input input:focus,
.auction-form-input textarea:focus {
  outline-color: #6c757d;
  outline-width: 2px;
}

.auction-from .inputErrors {
  color: #e63946;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.auction-form-input .inputErrors {
  color: #e63946;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}

.auction-form-buttons {
  margin: 2rem 0;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.auction-form-buttons button {
  padding: 0.7rem 0.8rem;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  transition: all 200ms ease;
  color: white;
  font-size: 0.7rem;
}

.auction-form-buttons .submit-auction {
  background-color: #ff8500;
  color: white;
}

.auction-form-buttons .submit-auction:hover {
  background-color: #ff9e00;
}

.auction-form-buttons .draft-auction {
  background-color: #001524;
}

.auction-form-buttons .draft-auction:hover {
  background-color: #15616d;
}

.auction-form-buttons .auction-cancel {
  background-color: #bd1f36;
}

.auction-form-buttons .auction-cancel:hover {
  background-color: #e01e37;
}

@media screen and (min-width: 768px) {
  .auction-form-buttons button {
    padding: 0.7rem 1.3rem;
    font-size: 1rem;
  }
}

.icon-input-contain {
  position: relative;
}

.icon-input-c {
}

.iicd {
  position: absolute;
  top: 59%;
  transform: translateY(-50%);
  /* left: 2%; */
  background-color: #e9ecef;
  width: 40px;
  height: 83%;
  padding: 1rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.auction-from button[disabled] {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.auction-from button[disabled]:hover {
  background-color: #bdc3c7;
}
