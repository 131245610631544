/* .button-default-style {
  padding: 0.8rem 1rem;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #919193;
  color: #212121;
  border: 0;
  outline: 0;
  border-radius: 0.3rem;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 200ms ease;
  color: white;
}

.button-default-style:hover {
  background-color: #d3d3d3;
  color: black;
} */

.button-default-style {
  padding: 0.7rem 0.8rem;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  transition: all 200ms ease;
  color: white;
  font-size: 1.1rem;
  background-color: #001524;
}

.button-default-style:hover {
  background-color: #15616d;
}

@media screen and (min-width: 768px) {
  .button-default-style {
    padding: 0.7rem 1.3rem;
    /* font-size: 1rem; */
  }
}

/* &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  } */

/* .button-default-style:focus, */
/* .button-default-style:hover { */
/* outline: 0; */
/* color: #fff; */
/* } */

/* .button-default-style {
  appearance: none;
  background-color: white;
  border: 2px solid #f1c40f;
  border-radius: 0.6em;
  color: red;
  cursor: pointer; */
/* display: flex; */
/* align-self: center; */
/* font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  padding: 0.8rem 1rem;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;

  background-color: #f1c40f; */
/* outline:  #f1c40f; */
/* background: linear-gradient(45deg, #f1c40f 50%, transparent 50%); */
/* background-position: 100%;
  background-size: 400%;

  transition: background 300ms ease-in-out;
}

.button-default-style:hover {
  background-position: 0;
} */

/* 
.button-default-style {
  background: darkblue;
  color: white;
  border-radius: 5px;
  transition: all 300ms;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border: none;
}

.button-default-style:hover {
  transform: scale(1.1);
  letter-spacing: 2px;
  background: white;
  color: darkblue;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.57);
} */
