.modal-container {
  position: fixed;
  background-color: white;
  z-index: 190;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  width: 90%;
  max-width: 500px;
  border-radius: 4px;
  /* border: 1.5px solid black; */
  box-shadow: 0 0 2px 2px #293241;
}

.mcheight {
  height: 90vh;
  overflow: auto;
}

.modal-container::-webkit-scrollbar {
  width: 5px;
}

.modal-container::-webkit-scrollbar-track {
  background: white;
}

.modal-container::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  border-radius: 7px;
  border: 1px solid #b2aeae;
}

.modal-container button {
  cursor: pointer;
}

.modal-container h2 {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.modal-container p {
  margin-bottom: 1rem;
}

.modal-btns {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.modal-btns button {
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 4px;
}

.modal-btns .cancel {
  color: white;
  background-color: red;
}

.modal-btns .cancel:hover {
  background-color: rgba(255, 0, 0, 0.772);
}

.modal-btns .success {
  color: white;
  background-color: rgba(5, 171, 19, 0.856);
}

.modal-btns .success:hover {
  background-color: lightgreen;
}
