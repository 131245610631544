.tagsList-tagMa {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 1rem;
  /* align-items: center; */
  position: relative;
  /* justify-content: space-between; */
}

.headi {
  font-size: 1.5rem;
  padding: 1rem;
}

.tagsList-tagMa .div .li {
  /* display: flex; */
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 1fr 10px 1fr;
  align-items: center;
  /* justify-content: center; */
  padding: 1rem;
  position: relative;
  gap: 20px;
}

.gItem {
  /* place-self: center; */
  justify-self: end;
}

.aspn {
  text-transform: capitalize;
  font-size: 0.8rem;
  justify-self: start;
}

.tagsList-tagMa .li {
  max-height: 62px;
  /* background-color: #e9ecef; */
  background-color: #f8f9fa;
  position: relative;
  /* color: #fff; */
  color: #273043;
  border-radius: 0.5rem;
  /* box-shadow: 0px 0px 1px 1px #778da9;
  -webkit-box-shadow: 0px 0px 1px 1px #778da9;
  -moz-box-shadow: 0px 0px 1px 1px #778da9; */
  outline: 1px solid #004e64;
  cursor: pointer;
  transition: all 200ms ease;
}

.tagsList-tagMa .li:hover {
  /* background-color: #e9ecef; */
  background-color: #d8f3dc;
}

.li h2 {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
  height: 20px;
}

.tagFlexCont {
  height: 140px;
  width: 100%;
  position: absolute;
  z-index: 2;
  max-width: 284px;
  /* max-height: fit-content; */
  background-color: #e9ecef;
  /* color: #fff; */
  color: #273043;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px 1px #778da9;
  -webkit-box-shadow: 0px 0px 2px 1px #778da9;
  -moz-box-shadow: 0px 0px 2px 1px #778da9;
  padding: 5px;
}

.tagFlexContCat {
  height: 225px;
  width: 100%;
  max-width: 284px;
  /* max-height: fit-content; */
  background-color: #e9ecef;
  /* color: #fff; */
  color: #273043;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2px 1px #778da9;
  -webkit-box-shadow: 0px 0px 2px 1px #778da9;
  -moz-box-shadow: 0px 0px 2px 1px #778da9;
  padding: 5px;
}

.fl {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 5px;
}
.a {
  font-size: 13px;
}

.tagFlexCont .flex {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  gap: 10px;
}

.tagFlexCont button {
  background-color: transparent;
  font-size: 20px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: 200ms ease;
}

.tagFlexCont .s {
  /* background-color: #7bf1a8; */
  border: 1px solid #60d394;
}

.s:hover {
  background-color: #7bf1a8;
}

.tagFlexCont .c {
  border: 1px solid #fe5f55;
}

.c:hover {
  background-color: #fe5f55;
}

.tagFlexCont input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  border: 0;
  box-shadow: 0px 0px 2px 1px #778da9;
  -webkit-box-shadow: 0px 0px 2px 1px #778da9;
  -moz-box-shadow: 0px 0px 2px 1px #778da9;
}

.tagFlexCont input:focus {
  outline: 0;
}

.ier {
  font-size: 10px;
  /* margin-top: 4px; */
  color: red;
}

@media screen and (min-width: 668px) {
  .tagsList-tagMa {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .tagsList-tagMa {
    grid-template-columns: repeat(3, 1fr);
  }
  .li h2 {
    max-width: 20ch;
  }
}
