.overlay-container {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
}

#outer {
  /* margin: auto; */
  /* margin-top: 50%; */
  position: relative;
  background: #16324f;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 100px;
  border-radius: 15px;
}

#middle {
  height: 60px;
  width: 60px;
  margin: auto;
  position: relative;
  top: 20px;
  border-radius: 50%;
  background-image: linear-gradient(150deg, transparent 50%, #154e7d 50%),
    linear-gradient(90deg, #154e7d 50%, white 50%);
  /* background-color: black; */
  -webkit-animation: rotation 1200ms infinite linear;
  animation: rotation 1200ms infinite linear; /* Standard */
  transform-origin: 50% 50%;
  animation-timing-function: ease;
}

#inner {
  background: #1b2845;
  height: 48px;
  width: 48px;
  margin: auto;
  position: relative;
  top: 6px;
  border-radius: 50%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(630deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(630deg);
  }
}

.example {
  display: block;
  height: 200px;
  position: relative;
  letter-spacing: 0.5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner::after {
  content: "";
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  border-radius: 50%;
}

.spinner.smooth::after {
  border-top: 4px solid rgba(255, 255, 255, 1);
  border-left: 4px solid rgba(255, 255, 255, 1);
  border-right: 4px solid rgba(255, 255, 255, 0);
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes grow-pulse {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  60% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
