.auction-item-user {
  padding: 1rem;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  width: 200px;
  margin: 1rem 0;
}

.user-auctions-display {
  overflow-y: auto;
  height: 100vh;
}

.auction-item-user button {
  margin: 1rem;
}

.hed {
  margin-top: 1.5rem;
  font-size: 1.3rem;
}

.user-actions-div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

.product-card {
  width: 100%;
  border-radius: 1rem;
  max-width: 250px;
  position: relative;
  box-shadow: 0 2px 7px #dfdfdf;
  margin: 50px auto;
  overflow: hidden;
  /* max-height: 600px; */
  background: #fafafa;
}

.product-card p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 4em;
  margin-bottom: 10px;
}

.badge {
  position: absolute;
  left: 0;
  top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  background: red;
  color: #fff;
  padding: 3px 10px;
}

.product-tumb {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  padding: 10px;
  background: #f0f0f0;
}

.product-tumb img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 1rem;
}

.product-details {
  padding: 10px;
}

.product-catagory {
  display: block;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ccc;
  margin-bottom: 18px;
}

.product-details h4 {
  font-weight: 500;
  display: block;
  margin-bottom: 18px;
  text-transform: uppercase;
  color: #363636;
  text-decoration: none;
  transition: 0.3s;
}
/* 
.product-details h4 a:hover {
  color: #fbb72c;
} */

.product-details p {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 18px;
  color: #999;
}

.product-bottom-details {
  overflow: hidden;
  border-top: 1px solid #eee;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auctionIsApproved-btn {
  color: #4ad66d;
  background-color: transparent;
  border: 0;
}

.product-bottom-details div {
  /* float: left; */
  /* width: 50%; */
}

.product-price {
  font-size: 18px;
  color: #fbb72c;
  font-weight: 600;
  width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-links {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-auction-cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}

.user-auctions-display {
  overflow-y: auto;
  height: 100vh;
}

.user-auctions-display::-webkit-scrollbar {
  width: 5px;
}

.user-auctions-display::-webkit-scrollbar-track {
  background: white;
}

.user-auctions-display::-webkit-scrollbar-thumb {
  background-color: #8f54a0a4;
  border-radius: 7px;
  border: 1px solid #b2aeae;
}

.edit-btn,
.delete-btn,
.create-btn {
  padding: 0.6rem 1rem;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  transition: all 200ms ease;
}

.link {
  color: white;
  background-color: #ff002b;
  padding: 0.3rem 0.7rem;
  border-radius: 0.2rem;
  text-decoration: none;
  transition: all 200ms ease;
}

.link:hover {
  background-color: #ff002bb8;
}

.delete-btn {
  background-color: #ff002b;
}

.edit-btn {
  background-color: #22223b;
}

.edit-btn:hover {
  background-color: rgba(1, 42, 74, 0.822);
}

.delete-btn:hover {
  background-color: #ef233c;
}

.create-btn {
  background-color: #4ad66d;
}

.create-btn:hover {
  background-color: #b7efc5;
}

.create-new-auction {
  display: grid;
  gap: 5px;
  padding: 10px;
  grid-template-columns: repeat(2, 1fr);
  /* padding-left: 1rem; */
  /* margin: 1rem 0px; */
}

.auctions-btn-card {
  /* width: fit-content; */
  /* margin: 0 0.3rem; */
  margin-top: 6px;
  padding: 0.4rem;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 0;
  outline: 0;
  background-color: white;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 4px 2px #dee2e6;
  -webkit-box-shadow: 0px 0px 4px 2px #dee2e6;
  -moz-box-shadow: 0px 0px 4px 2px #dee2e6;
  cursor: pointer;
  font-size: 0.8rem;
  text-align: left;
  transition: all 200ms ease;
}

.auctions-btn-card:hover {
  background-color: #e9ecef;
}

.icon-div-con {
  padding: 10px;
  border-radius: 999px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bgColoractive {
  background-color: #2b2d42;
  color: white;
}

.bgColoractive:hover {
  background-color: #2b2d42;
}

.payment-item {
  max-width: 300px;
  background-color: #b7efc5;
  color: #22223b;
  border-radius: 10px;
  padding: 10px;
}

.payment-item div {
  margin-top: 20px;
}

.payment-item div h3 {
  font-size: 1.2rem;
  margin: 10px 0;
}

.payment-item img {
  width: 100%;
  border-radius: 10px;
  height: 200px;
  object-fit: contain;
}

.paymentContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

@media screen and (min-width: 500px) {
  .auctions-btn-card {
    margin: 0 0.3rem;
    margin-top: 6px;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 668px) {
  .user-auction-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .create-new-auction {
    grid-template-columns: repeat(3, 1fr);
    /* padding-left: 1rem; */
  }
  /* .user-auction-cards {
    grid-template-columns: repeat(2, 1fr);
  } */
}

@media screen and (min-width: 1024px) {
  .create-new-auction {
    /* display: flex; */
    gap: 2px;
  }

  .user-auction-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .create-new-auction {
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
  }
  .three-items {
    grid-template-columns: repeat(4, 1fr);
  }
}
