.email-contain {
  padding: 1rem;
}
.email-notification {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  gap: 20px;
  padding: 1rem;
  cursor: pointer;
  /* background-color: #e9ecef; */
  outline: 2px solid #d8f3dc;
  margin-top: 1rem;
  border-radius: 10px;
  transition: all 200ms ease;
}

.sms-notification {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  gap: 20px;
  padding: 1rem;
  cursor: pointer;
  outline: 2px solid #d8f3dc;
  margin-top: 1rem;
  border-radius: 10px;
  transition: all 200ms ease;
}

.sms-notification:hover {
  background-color: #c7f9cc;
}

.email-notification:hover {
  background-color: #c7f9cc;
}

.sms-notification p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: span 4;
  padding: 10px;
}

.sms-notification h2 {
  grid-column: span 3;
}

.email-notification p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: span 3;
  padding: 10px;
}

.email-notification h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: span 2;
  padding: 10px;
}
