.filterBtn-container {
  gap: 4px;
  display: flex;
  width: fit-content;
  /* padding: 5px; */
  padding: 1rem 0;
  padding-left: 1.3rem;
}
.heading {
  padding: 1rem 0.5rem;
  padding-bottom: 0rem;
  /* padding-left: 1.3rem; */
  font-size: 1.5rem;
}
.filterBtn-container button {
  padding: 0.6rem 1.5rem;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: all 200ms ease;
}

.reject-button,
.approve-button {
  padding: 0.6rem 1rem;
  border: 0;
  color: white;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  transition: all 200ms ease;
}

.reject-button {
  background-color: #ff002b;
}

.approve-button {
  background-color: #2dc653;
}

.approvedStatus {
  color: #4ad66d;
}

.completedStatus {
  color: #4ad66d;
}

.suspendedStatus {
  color: #fb5607;
}

.closedStatus {
  color: #ef233c;
}
.rejectedStatus {
  color: #ef233c;
}

.pendingStatus {
  color: #ffc300;
}

.trashedStatus {
  color: #bf0603;
}
.soldStatus {
  color: #60d394;
}

.payment_failedStatus {
  color: #bf0603;
}

.pending_paymentStatus {
  color: #fb5607;
}

.reject-button:hover {
  background-color: #bf0603;
}

.approve-button:hover {
  background-color: #4ad66d;
}

.all {
  background-color: #22223b;
}
.pending {
  background-color: #ffc300;
}
.suspended {
  background-color: #fb5607;
}
.open {
  background-color: #2dc653;
}
.paid {
  background-color: #4ad66d;
}
.unpaid {
  background-color: #ef233c;
}
.closed {
  background-color: #6c757d;
}
.deleted {
  background-color: #bf0603;
}

.all:hover {
  background-color: rgba(1, 42, 74, 0.822);
}
.pending:hover {
  background-color: #ffdd00;
}
.suspended:hover {
  background-color: #ff8800;
}
.open:hover {
  background-color: #92e6a7;
}
.paid:hover {
  background-color: #b7efc5;
}
.unpaid:hover {
  background-color: #ff002b;
}

.closed:hover {
  background-color: #8d99ae;
}
.deleted:hover {
  background-color: #dd2d4a;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
}

.tagSpace {
  color: #6c757dc7;
  margin-left: 10px;
}

.auction-item h3 {
  text-transform: capitalize;
}
