.edit-req-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  padding: 1rem;
}

.edit-auction-item {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  margin-top: 1rem;
  border-radius: 0.4rem;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  transition: all 200ms ease;
}

.edit-auction-item:hover {
  background-color: #c7f9cc;
}

.edit-auction-item h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: center;
  /* width: 3cm; */
  width: 100%;
  font-weight: bold;
}

.edit-auction-item p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: center;
  width: 100%;
}

.edit-auction-item h3,
.edit-auction-item p,
.edit-auction-item .span,
.edit-auction-item .tag,
.a-btns {
  /* flex-grow: 1; */
  /* border-left: 1px solid #ddd; */
  padding: 8px;
}

.edit-auction-item div,
.edit-auction-item h3,
.edit-auction-item span {
  justify-self: center;
}

.edit-auction-item h3,
.edit-auction-item p {
  grid-column: span 2;
}

@media (min-width: 768px) {
  .edit-auction-item {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 900px) {
  .edit-auction-item {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 1024px) {
  .edit-auction-item {
    grid-template-columns: repeat(8, 1fr);
  }
  .edit-req-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
