.gallery-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  /* padding: 0rem 1rem; */
  background-color: #e9ecefb3;
  border-radius: 0.4rem;
  /* background-color: rgba(0, 0, 0, 0.1); */
  min-height: 300px;
  height: 100%;
  width: 100%;
}

.button-prev,
.button-next {
  position: absolute;
  font-family: "Material Icons";
  font-size: 4rem;
  cursor: pointer;
  user-select: none;
}

.button-prev {
  left: 5%;
}

.button-next {
  right: 5%;
}

.button-prev:hover,
.button-next:hover {
  color: white;
}

.gallery-track {
  aspect-ratio: 168/100;
  position: relative;
  width: 80%;
  overflow: hidden;
}

.slide-image {
  aspect-ratio: 16/9; /* Standard widescreen aspect ratio */
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: center;
}

.gallery-footer {
  position: absolute;
  bottom: 5%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.bubble-outer {
  cursor: pointer;
  border: 3px solid black;
  border-radius: 8px;
}

.bubble-inner {
  border: 5px solid transparent;
  border-radius: 4px;
}

@keyframes slide-right-in {
  from {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-left-in {
  from {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-right-out {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slide-left-out {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.rightIn {
  -webkit-animation: slide-right-in 0.5s 1 forwards;
  -moz-animation: slide-right-in 0.5s 1 forwards;
  -ms-animation: slide-right-in 0.5s 1 forwards;
  -o-animation: slide-right-in 0.5s 1 forwards;
  animation: slide-right-in 0.5s 1 forwards;
}

.leftIn {
  -webkit-animation: slide-left-in 0.5s 1 forwards;
  -moz-animation: slide-left-in 0.5s 1 forwards;
  -ms-animation: slide-left-in 0.5s 1 forwards;
  -o-animation: slide-left-in 0.5s 1 forwards;
  animation: slide-left-in 0.5s 1 forwards;
}

.rightOut {
  -webkit-animation: slide-right-out 0.5s 1 forwards;
  -moz-animation: slide-right-out 0.5s 1 forwards;
  -ms-animation: slide-right-out 0.5s 1 forwards;
  -o-animation: slide-right-out 0.5s 1 forwards;
  animation: slide-right-out 0.5s 1 forwards;
}

.leftOut {
  -webkit-animation: slide-left-out 0.5s 1 forwards;
  -moz-animation: slide-left-out 0.5s 1 forwards;
  -ms-animation: slide-left-out 0.5s 1 forwards;
  -o-animation: slide-left-out 0.5s 1 forwards;
  animation: slide-left-out 0.5s 1 forwards;
}

.pre-img,
.post-img {
  font-size: 50px;
  padding: 1rem;
  cursor: pointer;
  margin: 0 10px;
  transition: all 200ms ease;
}

.pre-img:hover,
.post-img:hover {
  background-color: #00303d;
  color: white;
}
