.userDetailsModal {
  position: fixed;
  inset: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.user-overview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* padding: 2rem; */
  gap: 20px;
}

.user-span {
  /* grid-column: span 3; */
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.user-overview div {
  padding: 1rem;
  border-radius: 0.7rem;
  background-color: #d2d4ff;
  color: #081225;
  height: fit-content;
  box-shadow: 0 0 5px #fff;
}

.address {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.address-2 {
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
}

.user-overview div h3 {
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all; /* For older browsers */
  overflow-wrap: break-word;
}
.user-overview div p {
  margin-bottom: 10px;
  word-break: break-all; /* For older browsers */
  overflow-wrap: break-word;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .user-overview {
    grid-template-columns: repeat(3, 1fr);
  }

  .user-span {
    grid-column: span 3;
  }

  .address {
    grid-template-columns: repeat(2, 1fr);
  }
}
