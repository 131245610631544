.formModal-container {
  position: fixed;
  inset: 0;
  background-color: #e9ecef;
  z-index: 90;
  padding: 1rem;
  overflow-y: scroll;
}

.formModal-content h2 {
  /* font-size: 1.8rem; */
  font-size: 1rem;
}

.formModal-content {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 4px;
  align-items: center;
}

.formModal-content button {
  padding: 0.7rem 0.8rem;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  transition: all 200ms ease;
  color: white;
  font-size: 0.7rem;
}

.formModal-content .cancel-auction-creation {
  background-color: #bd1f36;
}

.formModal-content .cancel-auction-creation:hover {
  background-color: #e01e37;
}

@media screen and (min-width: 768px) {
  .formModal-content h2 {
    font-size: 1.8rem;
  }
  .formModal-content button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}
