.auction-list {
  width: 100%;
  /* padding: 0.5rem; */
  border-radius: 4px;
  background-color: white;
}

.auction-item {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
  transition: all 200ms ease;
}

.auction-item:hover {
  background-color: #c7f9cc;
}
.auction-item img {
  /* height: 100%; */
  /* width: 100%; */
}

.auction-item:last-child {
  border-bottom: none;
}

.auction-item h3,
.auction-item p,
.auction-item .span,
.auction-item .tag,
.a-btns {
  /* flex-grow: 1; */
  border-right: 1px solid #ddd;
  padding: 8px;
}

.auction-item .tag {
  display: flex;
  gap: 2px;
  border-right: 0;
}

.auction-item h3 {
  /* flex-grow: 2; */
  grid-column: span 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.auction-item p {
  grid-column: span 3;
}

.a-btns {
  display: flex;
  gap: 5px;
  border-right: none;
  align-items: center;
  justify-content: center;
}

.auction-item p {
  flex-grow: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bgImg-admin {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.5rem;
}

.span {
  display: none;
}

@media (min-width: 768px) {
  /* .span {
    display: unset;
  } */
  .sta {
    display: block;
  }
  .auction-item {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 900px) {
  .span {
    display: unset;
    grid-column: span 2;
  }
  .auction-item {
    grid-template-columns: repeat(13, 1fr);
  }
}
