.dropdown-select-container {
  position: relative;
  width: 100%;
}

.dropdown-select-container h2 {
  font-size: 12px;
}

.flx {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* h2 {
  text-transform: uppercase;
  letter-spacing: 3px;
} */

.wrapper-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  /* max-width: 290px; */
  padding: 0 5px 0 10px;

  border-radius: 3px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  text-align: left;
  line-height: 45px;
  color: #444;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.wrapper-dropdown-width {
  max-width: 290px;
  box-shadow: 0;
}

.wrapper-dropdown::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;

  margin-top: -2px;
  width: 0;
  height: 0;

  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #a0a0a0 transparent;
}

.wrapper-dropdown.active::before {
  border-width: 0 6px 6px 6px;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  top: 110%;
  right: 0;
  left: 0;
  width: 150px;

  margin: 0;
  padding: 0;

  list-style: none;

  border-radius: inherit;
  box-shadow: inherit;
  /* background: inherit; */

  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;

  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);

  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  opacity: 0;
  visibility: hidden;
}

.wrapper-dropdown .dropdown li {
  padding: 0 15px;

  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;

  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
  background-color: white;
  color: black;
}

/* .wrapper-dropdown .price-range-dropdown li { */
/* overflow: unset; */
/* padding: 0; */
/* right: 100%; */
/* left: unset; */

/* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
/* } */

.wrapper-dropdown .dropdown li:last-child {
  border-bottom: none;
}

.wrapper-dropdown .dropdown li:hover {
  background: rgb(197, 239, 247);
}

.wrapper-dropdown.active .dropdown {
  opacity: 1;
  visibility: visible;
  z-index: 6;
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
}

.wrapper-dropdown .price-range-dropdown li {
  position: absolute;
  overflow: unset;
  padding: 0;
  right: 0%;
  margin-top: 8px;
}

.wrapper-dropdown .price-range-dropdown {
  width: fit-content;
  background-color: aqua;
  padding: 0rem;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

.wrapper-dropdown.active .price-range-dropdown {
  opacity: 1;
  visibility: visible;
  z-index: 6;
  pointer-events: all;
}

.wrapper-dropdown span {
  font-size: 12px;
}

.wrapper-dropdown .dropdown li {
  font-size: 12px;
}

@media screen and (min-width: 600px) {
  .wrapper-dropdown .dropdown {
    width: 100%;
  }
  .dropdown-select-container h2 {
    font-size: 16px;
  }
  .wrapper-dropdown .dropdown {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  /* .dropdown-select-container {
    width: 100%;
  } */
  /* .wrapper-dropdown .dropdown {
    width: 100%;
  } */

  .wrapper-dropdown-width {
    width: 280px;
    max-width: 290px;
  }
  .wrapper-dropdown .price-range-dropdown li {
    position: absolute;
    right: unset;
    left: 0;

    width: fit-content;
  }
}
