.adminInput {
  width: 100%;
  margin-top: 10px;
  padding: 13px;
  /* padding-left: 55px; */
  font-weight: bold;
  border: 0;
  /* outline: 2px solid rgb(211, 211, 211); */
  outline: 0.8px solid #919193;
  border-radius: 5px;
  /* margin-bottom: 6px; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.subAdminForm {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 100%;
}

.checkBox {
  /* background-color: aqua; */
  padding: 0.8rem;
  cursor: pointer;
  transition: all 200ms ease;
  border-radius: 0.5rem;
}

.checkBox:hover {
  background-color: #e2eafc;
}

.btn-submit {
  width: fit-content;
}

@media screen and (min-width: 768px) {
  .subAdminForm {
    grid-template-columns: repeat(2, 1fr);
  }
}
