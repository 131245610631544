.input-tag {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.input-tag input {
  border: none;
  width: 100%;
  display: none;
}

.input-tag__tags div h2 {
  margin-bottom: 10px;
}
.tags-list {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.fx {
  display: flex;
  gap: 20px;
}

.li-tag {
  display: flex;
  align-items: center;
  padding: 7px;
  background-color: #dee2e6;
  border-radius: 4px;
  gap: 10px;
}

.li-tag button {
  cursor: pointer;
}

.predifined-tags {
  background-color: #e9ecef;
  border: 0;
  padding: 7px;
  border-radius: 4px;
  cursor: pointer;
}

.predifined-tags:hover {
  background-color: #dee2e6;
}

.catSlection {
  display: flex;
}
