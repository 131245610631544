.notifications-container {
  padding: 0.7rem;
}
.notifications {
  border-radius: 10px;
  background-color: #e9ecef;
  padding: 1rem;
  margin-bottom: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.notifications p {
  margin-top: 10px;
}
