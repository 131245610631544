/* email-template.css */
:root {
  --primary-color: #2563eb;
  --primary-hover: #1d4ed8;
  --secondary-color: #f3f4f6;
  --border-color: #e5e7eb;
  --text-color: #111827;
  --text-secondary: #4b5563;
  --sidebar-width: 300px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  line-height: 1.5;
  color: var(--text-color);
}

/* Layout */
.app-container {
  display: flex;
  height: 100vh;
  background-color: white;
}

/* Sidebar */
.sidebar {
  width: var(--sidebar-width);
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100vh; /* Full viewport height */
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
  flex-shrink: 0; /* Prevent header from shrinking */
}

.sidebar-header h2 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}

.template-list {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  position: relative; /* For loading indicator */
}

.loading-indicator {
  padding: 12px;
  text-align: center;
  color: var(--text-secondary);
  background-color: var(--secondary-color);
  border-radius: 6px;
  margin-top: 8px;
}

.template-item {
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  background: none;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
  margin-bottom: 8px;
  transition: all 0.2s;
}

.template-item:hover {
  background-color: var(--secondary-color);
}

.template-item.active {
  background-color: var(--secondary-color);
  font-weight: 500;
}

/* Content Area */
.content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-header {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.content-title {
  font-size: 24px;
  font-weight: 600;
}

.header-actions {
  display: flex;
  gap: 8px;
}

.view-toggle {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  overflow: hidden;
}

.view-toggle .button-outline {
  border: none;
  border-radius: 0;
  padding: 6px 12px;
}

.view-toggle .button-outline.active {
  background-color: var(--primary-color);
  color: white;
}

.view-toggle .button-outline:not(:last-child) {
  border-right: 1px solid var(--border-color);
}

.editor-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Form Elements */
.form-group {
  margin-bottom: 20px;
}

.label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}

.input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
}

.textarea {
  width: 100%;
  min-height: 400px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-family: monospace;
  font-size: 14px;
  resize: vertical;
}

/* Preview Mode */
.preview-mode {
  height: 100%;
  background-color: var(--secondary-color);
  padding: 20px;
  overflow-y: auto;
}

.preview-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.preview-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.language-selector {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.preview-email {
  padding: 20px;
}

.preview-subject {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

.preview-body {
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  min-height: 400px;
}

/* Buttons */
.button-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
}

.button-primary:hover {
  background-color: var(--primary-hover);
}

.button-outline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background: none;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s;
}

.button-outline:hover {
  background-color: var(--secondary-color);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

/* Language Tabs */
.language-tabs {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}

/* Continuing the language tabs styles */
.tab {
  padding: 8px 16px;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: 14px;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s;
}

.tab:hover {
  color: var(--text-color);
}

.tab.active {
  color: var(--primary-color);
  border-bottom-color: var(--primary-color);
}

/* Form Actions */
.form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.form-actions .button-primary {
  width: auto;
}

/* Focus States */
.input:focus,
.textarea:focus,
.language-selector:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

/* Disabled States */
.button-primary:disabled,
.button-outline:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .content-area {
    height: 0;
    flex: 1;
  }

  .content-header {
    flex-direction: column;
    gap: 16px;
  }

  .header-left {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .view-toggle {
    width: 100%;
  }

  .view-toggle .button-outline {
    flex: 1;
    justify-content: center;
  }

  .header-actions {
    width: 100%;
  }

  .header-actions .button-outline {
    flex: 1;
  }

  .preview-container {
    margin: 0;
  }

  .textarea {
    min-height: 200px;
  }

  .form-actions {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 16px;
    margin: 0 -20px -20px -20px;
    border-top: 1px solid var(--border-color);
  }

  .form-actions .button-primary {
    width: 100%;
  }
}

/* Small screen adjustments */
@media (max-width: 480px) {
  .content-header {
    padding: 16px;
  }

  .editor-content {
    padding: 16px;
  }

  .preview-mode {
    padding: 16px;
  }

  .preview-email {
    padding: 16px;
  }

  .preview-body {
    padding: 16px;
  }
}

/* Print styles */
@media print {
  .sidebar,
  .content-header,
  .form-actions {
    display: none;
  }

  .app-container {
    height: auto;
  }

  .preview-mode {
    padding: 0;
    background-color: white;
  }

  .preview-container {
    box-shadow: none;
  }
}

/* Utility Classes */
.hidden {
  display: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.no-scroll {
  overflow: hidden;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

/* Variables Menu Styles */
.variables-wrapper {
  position: relative;
}

.variables-menu {
  position: absolute;
  top: calc(100% + 8px); /* Add some space from the button */
  right: 0;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  min-width: 240px;
  z-index: 1000;
  padding: 8px 0;
  max-height: 400px;
  overflow-y: auto;
}

/* Add a subtle header to the variables menu */
.variables-menu::before {
  content: "Available Variables";
  display: block;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 8px;
}

.variable-item {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  font-family: monospace;
  font-size: 13px;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  align-items: center;
}

.variable-item:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

/* Add a small indicator for insert action */
.variable-item::after {
  content: "+ Insert";
  font-size: 11px;
  font-family: system-ui;
  color: var(--text-secondary);
  margin-left: auto;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.variable-item:hover::after {
  opacity: 1;
}

/* Add a subtle arrow pointing to the button */
.variables-menu::after {
  content: "";
  position: absolute;
  top: -4px;
  right: 16px;
  width: 8px;
  height: 8px;
  background: white;
  transform: rotate(45deg);
  border-left: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
}

/* Add fade-in animation */
@keyframes menuFadeIn {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.variables-menu {
  animation: menuFadeIn 0.2s ease-out;
}

/* Close menu when clicking outside */
.variables-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

@keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.template-skeleton {
  height: 40px;
  margin-bottom: 8px;
  background: linear-gradient(
    90deg,
    var(--secondary-color) 25%,
    #e5e7eb 50%,
    var(--secondary-color) 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 6px;
}
