.dropdown {
  position: absolute;
  /* top: 50px; */
  /* margin: 0 auto; */
  /* width: 220px; */
  /* border: 1px solid #666; */
  top: 0;
  right: 10px;
  z-index: 30;
  background-color: #12263a;
}

.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 10px 0;
  position: relative;
}

.dropdown__text {
  color: white;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;
}

.dropdown__text:hover {
  color: white;
  background: #0b4f6c;
}

.dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid #ccc;
  border-width: 0 1px 1px 0;
  float: right;
  margin-top: 8px;
  margin-right: 6px;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdown.active .dropdown__text:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
}

.dropdown.active .dropdown__items {
  visibility: visible;

  border-top: 1px solid #666;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.7s, opacity 3s, visibility 4s ease;
}

.dropdown__item {
  color: white;
  cursor: pointer;
  padding: 10px 18px;
}

.dropdown__item:not(:last-child) {
  border-bottom: 1px solid #666;
}

.dropdown__item:hover {
  color: white;
  background: #0b4f6c;
}
