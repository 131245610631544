.input-field {
}

.input-field-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.input-field img {
  /* width: 25px; */
  position: absolute;
  /* left: 10px; */
  padding: 0.7rem;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1.2px solid #919193;
  background-color: #f1f3f5;
}

.input-field div {
  width: 100%;
}

.input-field div input,
.text-area {
  width: 100%;
  padding: 13px;
  /* padding-left: 55px; */
  font-weight: bold;
  border: 0;
  /* outline: 2px solid rgb(211, 211, 211); */
  outline: 0.8px solid #919193;
  border-radius: 5px;
  /* margin-bottom: 6px; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.text-area {
  min-height: 100px;
  max-height: 500px;
  min-width: 100%;
}

.input-field input:focus {
  outline: 1.2px solid #919193;
}

.input-field input::placeholder {
  color: #ccc8c8;
  /* letter-spacing: 2px; */
  /* font-weight: 600; */
}

.field-errors {
  outline: 2px solid red;
  border-radius: 5px;
}
.input-field-error {
  color: red;
  font-size: 0.7rem;
  font-weight: 500;
}

.card-form .month-input,
.card-form .year-input {
  width: 44%;
  margin-right: 15px;
}
.card-form select {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ced6e0;
  box-shadow: none;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
}
.card-form select:hover,
.card-form select:focus {
  border-color: #3d9cff;
}
.card-form select:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-form select {
  -webkit-appearance: none;
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}

.card-form .payment-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.lg-input {
  grid-column: span 3;
}

.pcf {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.card-form {
  /* margin: 0 auto; */
  width: 100%;
  max-width: 700px;
}
.card-form .card-inputs {
  /* background: #fff; */
  /* box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4); */
  /* border-radius: 10px; */
  /* padding-top: 150px; */
}
.card-form form {
  margin: 0 auto;
  /* padding: 35px; */
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 2fr 1fr;
  width: 100%;
}
.card-form .lg-input {
  grid-column: 1/4;
}
.card-form .med-input {
  grid-column: 1/3;
}
.card-form .sm-input {
  grid-column: 3/4;
}
label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 300;
  color: #616161;
  width: 100%;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.card-form .name-input,
.card-form .number-input,
.card-form .cvv-input {
  width: 100%;
}
.card-form .month-input,
.card-form .year-input {
  width: 44%;
  margin-right: 15px;
}
.card-form input,
.card-form select {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ced6e0;
  box-shadow: none;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
}

.card-form select:hover,
.card-form select:focus {
  border-color: #3d9cff;
}
.card-form select:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-form select {
  -webkit-appearance: none;
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.card-form button {
  height: 55px;
  background-color: #001524;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: fit-content;
  padding: 0.5rem 1.5rem;
}

.card-form button:hover {
  background-color: #15616d;
}

.card-form button.disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.card {
  margin: 0 auto;
  width: 430px;
  height: 270px;
  border-radius: 15px;
  position: relative;
}
.card.container {
  margin-bottom: -130px;
  perspective: 1500px;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.4s ease-in-out;
}
.card.container.show {
  opacity: 1;
  transform: translateY(0);
}
.card.container .inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
}
.card.container .inner .back,
.card.container .inner .front {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}
.card.container .inner .back {
  transform: rotateY(180deg);
}
.card .back {
  background: yellow;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  position: absolute;
  top: 0;
}
.card .overlay {
  background: rgba(6, 2, 29, 0.4);
}
.card .overlay,
.card .content,
.card .slider {
  position: absolute;
  top: 0;
}
.card .slider {
  transition: all 0.45s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.65);
}
.card .slider:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(8, 20, 47);
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card .slider.on-number {
  transform: translate(15px, 110px);
  opacity: 1;
  border-radius: 8px;
  width: 350px;
  height: 50px;
}
.card .slider.on-name {
  transform: translate(15px, 194px);
  opacity: 1;
  border-radius: 8px;
  width: 300px;
  height: 57px;
}
.card .slider.on-expiration {
  transform: translate(315px, 194px);
  opacity: 1;
  border-radius: 8px;
  width: 100px;
  height: 57px;
}
.card .content {
  padding: 25px 15px;
  color: #fff;
}
.card .content label {
  color: #fff;
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 5px;
}
.card .content .number {
  width: 92%;
  padding: 15px;
  margin-bottom: 30px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  position: relative;
  cursor: pointer;
}
.card .content .number .digit-wrapper {
  display: inline-block;
  width: 16px;
}
.card .content .number .digit-wrapper .digit {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 10px;
}
.card .content .number .digit-wrapper:nth-child(4n) {
  margin-right: 20px;
}
.card .content .shown {
  transform: translateY(0);
  opacity: 1;
}
.card .content .hidden:nth-child(1) {
  transform: translateY(-15px);
  opacity: 0;
}
.card .content .hidden:nth-child(2) {
  transform: translateY(15px);
  opacity: 0;
}
.card .content .name {
  width: 75%;
  padding-left: 15px;
  padding-top: 8px;
  position: relative;
  cursor: pointer;
}
.card .content .name .placeholder {
  transition: all 0.3s ease-in-out;
}
.card .content .name .name-container {
  position: absolute;
  top: 25px;
}
.card .content .name .character {
  animation: character-slide 0.6s ease-in-out forwards;
  display: inline-block;
}
.card .content .name .character.space {
  height: 100%;
  width: 8px;
}
@keyframes character-slide {
  0% {
    opacity: 0;
    transform: translateX(50px) rotate(180deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.card .content .expiration {
  width: 25%;
  padding-right: 10px;
  padding-left: 15px;
  padding-top: 5px;
  cursor: pointer;
}
.card .content .expiration .double-digit {
  display: inline-block;
  text-align: center;
}
.card .content .expiration .double-digit:nth-child(2n + 1) {
  width: 29px;
}
.card .content .expiration .double-digit:nth-child(2) {
  margin-left: 2px;
}
.card .content .expiration .toggle1 {
  animation: toggle1 0.24s ease-in-out forwards;
}
.card .content .expiration .toggle2 {
  animation: toggle2 0.24s ease-in-out forwards;
}
@keyframes toggle1 {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes toggle2 {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.card .content .name,
.card .content .expiration {
  display: inline-block;
  padding-bottom: 9px;
  font-weight: 500;
}
.card .content .name div,
.card .content .expiration div {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 1px;
}
