.dpw {
  background-color: white;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  padding: 1rem;

  margin: 1rem 0;
}
.datepicker-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  border-radius: 4px;
}

.datepicker-wrapper .l {
  width: 100%;
}

.datepicker {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
  max-width: 300px;
  outline: 1.2px solid gray;
  border-radius: 4px;
  display: block;
  margin-top: 10px;
}

.datepicker-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  user-select: none;
}

.datepicker-wrapper input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  user-select: none;
}

.selected-dates {
  margin: 0.8rem 0;
  color: #3a3a3a;
  font-size: 0.8rem;
  font-weight: 500;
}

.flex-date-time {
  display: flex;
  gap: 30px;
}

@media screen and (min-width: 768px) {
  .datepicker-wrapper {
    display: flex;
    gap: 20px;
  }
}
