.auctionItemDetailsModal {
  background-color: white;
  position: fixed;
  inset: 0;
  overflow-y: auto;
  z-index: 40;
}

.auctionItemDetailsModal-content {
  width: 90%;
  margin: 0 auto;
  max-width: 700px;
}

.img-slider img {
  width: 100%;
}

.auctionItemDetailsModal .heading-aidm {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
  align-items: center;
}

.heading-aidm h2 {
  font-size: 20px;
}

.title-aidm {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin: 1rem 0;
}

.title-aidm h3 {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #adb5bd;
}

.title-aidm p {
  font-size: 1.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.tags-aidm {
  display: flex;
  gap: 10px;
}

.bid-history h2 {
  color: #adb5bd;
  margin-top: 0.8rem;
}

.bid-history p {
  margin-bottom: 0.8rem;
}

.bid-history p {
  margin-bottom: 0.8rem;
}

/*  new Stylies */

.details-container {
  background-color: #081225;
  margin: 0 auto;
  /* padding: 1rem; */
  color: white;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  display: none;
}

.details-nav-container {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 2rem;
  padding: 2rem;
  /* padding-bottom: 2rem; */
  border-bottom: 1px solid white;
  /* justify-content: space-between; */
}

.tabs-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tabs-container button {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #4d525c;
  cursor: pointer;
  font-weight: bold;
  transition: all 200ms ease;
}

.tabs-container button:hover {
  color: white;
}

.tabs-container .active-btn {
  color: white;
}

.close-button {
  margin-left: auto;
  background-color: #1c2536;
  border-radius: 999px;
  color: white;
  border: 0;
  outline: 0;
  padding: 1rem;
  width: 50px;
  font-size: 22px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* flex-grow: 3; */
  transition: all 200ms ease;
}

.close-button:hover {
  background-color: #efeffe8a;
}
.overview-container {
  /* display: flex; */
  gap: 20px;
  padding: 1rem 2rem;
  /* max-width: 1200px; */
  margin: 0 auto;
  /* align-items: center; */
}

.img-slider {
  /* width: 50%; */
}

.overview-container h2 {
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 2rem;
}

.auction-overview div {
  padding: 1rem;
  border-radius: 0.7rem;
  background-color: #ffefe7;
  color: #081225;
  height: fit-content;
  box-shadow: 0 0 5px #fff;
}

.auction-overview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-top: 1rem;
}

/* .gridspan {
  grid-column: span 3;
}

.gridspanTwo {
  grid-column: span 2;
}

.gridSpanFull {
  grid-column: span 6;
} */

.auction-overview div h3 {
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all; /* For older browsers */
  overflow-wrap: break-word;
}
.auction-overview div p {
  margin-bottom: 10px;
  word-break: break-all; /* For older browsers */
  overflow-wrap: break-word;
  width: 100%;
}

.tags-container {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
}

.tags-container li {
  background-color: #081225;
  margin: 0;
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.tall {
  grid-row: span 2;
}

.actions-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 1rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
}

.actions-btns button {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 10px;
  cursor: pointer;
  border: 0;
  outline: 0;
  font-size: 17px;
  font-weight: bold;
  transition: all 200ms ease;
  padding: 0.6rem 1rem;
  border: 0;
  color: white;
  transition: all 200ms ease;
  box-shadow: 0 0 1px 1px #ffefe7;
  background-color: #22223b;
}

.actions-btns .edit-action-btn:hover {
  background-color: #57cc99;
  color: #081225;
}

.actions-btns .suspend-action-btn:hover {
  background-color: #ff9e00;
  color: #081225;
}

.actions-btns .delete-action-btn:hover {
  background-color: #ff002b;
}

/* bidders */

.bidders-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 2rem;
}

.h2-font {
  font-size: 23px;
  font-weight: 500;
  padding-left: 2rem;
}

.bidders-info {
  padding: 1rem;
  border-radius: 0.7rem;
  background-color: #e6eeff;
  color: #081225;
  height: fit-content;
  box-shadow: 0 0 5px #fff;
}

.bidders-info h2 {
  font-weight: bold;
  margin-bottom: 10px;
  word-break: break-all; /* For older browsers */
  overflow-wrap: break-word;
}

.bidders-info p {
  margin-bottom: 10px;
}

.icon-auction-button {
  display: none;
}

@media screen and (min-width: 500px) {
  .icon-container {
    display: flex;
  }
}

@media screen and (min-width: 668px) {
  .icon-auction-button {
    display: block;
  }
  .actions-btns {
    padding-right: 2rem;
  }

  .bidders-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .bidders-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .auction-overview {
    grid-template-columns: repeat(6, 1fr);
  }

  .bidders-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .gridspan {
    grid-column: span 3;
  }

  .gridspanTwo {
    grid-column: span 2;
  }

  .gridSpanFull {
    grid-column: span 6;
  }
}

.titleCap {
  text-transform: capitalize;
}

.link-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.link-container a {
  color: white;
}
