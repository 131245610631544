.users-list {
  padding: 0.5rem;
}
.all-users-list {
  /* overflow-y: auto; */
  /* max-height: 75vh; */
  /* width: 100%; */
  padding: 4px;
}
.user {
  background-color: white;
  display: flex;
  align-items: center;
  /* gap: 20px; */
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  justify-content: space-between;
}

/* td {
  padding: 1rem;
  border: 1px solid black;
}
th {
  text-align: left;
} */

.user:hover {
  background-color: #f1f1f1;
}

.user-contain-one {
  display: flex;
  align-items: center;
  /* gap: 20px; */
}

.table-header {
  display: flex;
  gap: 20px;
  margin-top: 3rem;
  font-size: 1.3rem;
}

.table-img {
  flex: 1;
}

.table-name,
.table-email {
  flex: 3;
}

.table-more,
.table-delete {
  flex: 1;
}

.user-contain-two {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-h3 {
  font-size: 1.7rem;
  text-transform: uppercase;
}

.user-contain-two div {
  cursor: pointer;
}

.user-search-field {
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
  border: 0;
  outline: 2px solid #5a8dee;
  border-radius: 4px;
}

.user-search-field:focus {
  outline-width: 3px;
}

.pagination-data {
  padding: 0;
  margin: 0;
}
.pagination-data li {
  list-style: none;
}
.table-filter-info {
  padding: 15px;
}
.thead-primary tr th {
  background-color: #5a8dee;
  border-color: #5a8dee;
  color: #fff;
}

.rc-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-total-text {
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
/* .rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
  display: block;
} */
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
  min-width: initial;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
  padding: 6px 8px;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #656f84 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}
.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
  background-color: #5a8dee;
  border-color: #5a8dee;
  color: #ffffff !important;
}
.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
  background-color: #eceff5;
  border-color: #eceff5;
}

.container {
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
  padding: 0 10px;
}

.responsive-table {
  background-color: #fefefe;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  border-collapse: collapse;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.02);
  width: 100%;
  margin: 2rem 0;
  /* overflow: hidden; */
}
.responsive-table__row {
  display: grid;
  border-bottom: 1px solid #edeef2;
  padding: 0 1.5rem;
}
@media (min-width: 768px) {
  .responsive-table__row {
    grid-template-columns: 3fr 3fr 1fr 3fr 1fr;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__row {
    grid-template-columns: 1fr 2fr 1fr;
  }
}
.responsive-table__row th,
.responsive-table__row td {
  padding: 1rem;
}
.responsive-table__head {
  background-color: #003049;
  color: white;
}
@media (max-width: 991px) {
  .responsive-table__head {
    display: none;
  }
}
.responsive-table__head__title {
  display: flex;
  align-items: center;
  font-weight: 500;
  text-transform: capitalize;
}
.responsive-table__body .responsive-table__row {
  transition: 0.1s linear;
  transition-property: color, background;
}
.responsive-table__body .responsive-table__row:last-child {
  border-bottom: none;
}
.responsive-table__body .responsive-table__row:hover {
  /* color: #ffffff; */
  background-color: #e9ecef;
  background-color: #c7f9cc;
  cursor: pointer;
}
.responsive-table__body__text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
}

.grid-item-users {
  /* justify-self: center; */
  /* align-self: center; */
}

.responsive-table__body__text::before {
  margin-right: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}
@media (max-width: 991px) {
  .responsive-table__body__text::before {
    content: attr(data-title) " :";
  }
}
@media (max-width: 400px) {
  .responsive-table__body__text::before {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.responsive-table__body__text--name {
  font-weight: 600;
}
@media (min-width: 768px) {
  .responsive-table__body__text--name::before {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--name {
    /* grid-column: 1/2; */
    /* flex-direction: column; */
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--status,
  .responsive-table__body__text--types,
  .responsive-table__body__text--update {
    grid-column: 2/3;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--country {
    grid-column: 3/-1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-table__body__text--name,
  .responsive-table__body__text--country {
    grid-row: 2;
  }
}
@media (min-width: 1000px) {
  .grid-item-users {
    justify-self: center;
    align-self: center;
  }
}

.up-arrow {
  height: 100%;
  max-height: 1.8rem;
  margin-left: 1rem;
}

.user-icon {
  width: 100%;
  max-width: 4rem;
  height: auto;
  margin-right: 1rem;
}

.status-indicator {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background: #222222;
  margin-right: 0.5rem;
}
.status-indicator--active {
  background: #25be64;
}
.status-indicator--inactive {
  background: #dadde4;
}
.status-indicator--new {
  background: #febf02;
}

.seller-user {
  text-transform: capitalize;
  color: #25be64;
}

.normal-user {
  text-transform: capitalize;
  color: #0077b6;
}

.pending-user,
.suspended-user {
  color: #febf02;
  text-transform: capitalize;
}
