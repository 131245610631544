.adminMessage {
  background-color: #eaf4f4;
  width: 50%;
  padding: 1rem;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 8px;
}

.userMessage {
  background-color: #1b4332;
  width: 50%;
  color: white;
  margin-bottom: 8px;
  padding: 1rem;
  margin-left: auto;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

.singleMessageItem {
  /* display: flex;
  flex-direction: column; */
  gap: 15px;
  padding: 0.8rem;
  margin-top: 5px;
  /* background-color: #cce3de; */
  /* margin-top: 6px; */
  cursor: pointer;
  overflow: hidden;
  border-radius: 1rem;
  transition: background-color 200ms ease;
  outline: 1px solid #b7e4c7;
}

.singleMessageItem:hover {
  background-color: #c7f9cc;
}

.messagesContainer {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.messageFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding: 0.7rem;
}

.messageFlex input {
  width: 300px;
  padding: 0.7rem;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .messagesContainer {
    grid-template-columns: 1fr;
  }
}
.messages-list {
  overflow-y: auto;
  height: 88vh;
  padding: 10px;
  padding-bottom: 1.5rem;

  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px; */
}
.allMessagesView {
  overflow-y: auto;
  background-color: #fff;
  height: 80vh;
  padding: 10px;
}

.messageActive {
  /* background-color: #eff6e0; */
  outline: 2px solid #2c6e49;
}

.messageMessage {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.messageEmail {
  font-weight: 500;
  font-size: 1.1rem;
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
  padding: 5px 0;
}

.defaultMessage {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.sendMessage {
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 10px;
  background-color: #eaf4f4;
}

.sendMessage input {
  width: 100%;
  padding: 1rem;
  border-radius: 2rem;
  border: 0;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
  outline: 0;
}
.sendMessage input:focus {
  outline: 2px solid rgb(56, 103, 214);
}

.sendMessage button {
  background-color: rgb(56, 103, 214);
  border: 0;
  outline: 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 999px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
}

.sendMessage button:hover {
  background-color: #000814;
}

.sendMessage button img {
  width: 20px;
}

@media (max-width: 767px) {
  /* .messages-list {
    grid-template-columns: 1fr;
  } */
}
.messages-list,
.allMessagesView {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.messages-list::-webkit-scrollbar,
.allMessagesView::-webkit-scrollbar {
  width: 1px;
}

.messages-list::-webkit-scrollbar-track,
.allMessagesView::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.messages-list::-webkit-scrollbar-thumb,
.allMessagesView::-webkit-scrollbar-thumb {
  background-color: #888;
}

.unreadMessageFromUser {
  width: 14px;
  min-width: 14px;
  min-height: 14px;
  height: 14px;
  background-color: #95d5b2;
  border-radius: 999px;
}

.flexContainerMessagesList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.emailAndMessage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-content-cc {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 10px;
  top: 10vh;
  border-radius: 10px;
  box-shadow: 0 0 3px 1px #000814;
}
